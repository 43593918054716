import ContentSection, { SectionType } from "./ContentSection";

type Props = {
  className: SectionType;
};

function Copyright({ className }: Props) {
  return (
    <ContentSection className={className}>
      <p style={{ fontSize: "65%", paddingRight: "130px", textAlign: "right" }}>
        &copy; Paulius Liekis 2016
      </p>
      <br />
    </ContentSection>
  );
}

export default Copyright;
