import ContentSection, { SectionType } from "../components/ContentSection";
import { storyFiles } from "./StoryFiles";
import ContentSection3 from "../components/ContentSection3";
import Copyright from "../components/Copyright";
import { Fragment } from "react/jsx-runtime";
import { Link } from "react-router-dom";

function Home() {
  const selectedPhotos = ["2", "6", "10", "9"];

  return (
    <>
      <ContentSection id="Video" className={SectionType.content1}>
        <p style={{ textAlign: "center" }}>
          <iframe
            src="https://player.vimeo.com/video/168164892"
            width="900"
            height="506"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </p>
      </ContentSection>

      {/* <ContentSection id="Photos">
        <p style={{ textAlign: "center" }}>
          {storyFiles.slice(0, 6).map((file) => (
            <Link href={`Story.tsx#${file[1]}`}>
              <img
                src={`Data/Photos/StoryThumbs/${file[0]}.jpg`}
                style={{ width: "300px", height: "200px" }}
              />
            </Link>
          ))}
          <br />
        </p>
      </ContentSection> */}

      <ContentSection className={SectionType.content2}>
        <p>
          If you haven't seen{" "}
          <a href="http://www.imdb.com/title/tt0113568/">Ghost In The Shell</a>{" "}
          movie - I highly recommend to watch it...
        </p>
        <p style={{ textAlign: "center" }}>
          <img src="Data/Photos/Movie.jpg" />
        </p>
      </ContentSection>

      <ContentSection3 background="Background1.png" height={400}>
        <p
          style={{
            textAlign: "center",
            fontStyle: "italic",
            fontFamily: "'Times New Roman', Time, serif",
            paddingLeft: "80px",
            paddingRight: "80px",
          }}
        >
          <img src="Data/Images/QuoteWhite1.png" />
          &nbsp;&nbsp;&nbsp;{" "}
          <span style={{ fontSize: "150%", lineHeight: "170%" }}>
            I love the idea that film directors are making same movie every time
            - they have same underlying idea through all their movies. I've been
            fascinated by motion, the beauty of it, how poses of body convey
            emotions. The tank from the final battle in Ghost In The Shell left
            a long lasting impression on me. It has such a rigid shape, but then
            moves in a very fluid way which makes it seem more like an animal,
            and then it becomes completely static making it purely mechanical
            again. Now I realize that this was something that I wanted to build
            for a long time and I just had to get it out of my system :)
          </span>{" "}
          &nbsp;&nbsp;&nbsp;
          <img src="Data/Images/QuoteWhite2.png" />
        </p>
      </ContentSection3>

      <ContentSection id="Photos" className={SectionType.content1}>
        <p>
          More photos <Link to="/Photos">here...</Link>
        </p>
        <p style={{ textAlign: "center" }}>
          {selectedPhotos.map((file) => (
            <Fragment key={file[0]}>
              <Link to={`/Photos#${file}.jpg`}>
                <img
                  src={`Data/Photos/Main/${file}.jpg`}
                  style={{ width: "450px", height: "253px" }}
                />
              </Link>
              &nbsp;
            </Fragment>
          ))}
        </p>
      </ContentSection>

      <ContentSection id="Photos" className={SectionType.content2}>
        <p style={{ textAlign: "center" }}>
          <iframe
            width="900"
            height="506"
            src="https://www.youtube.com/embed/E8oVaP1AIA8?si=dWIg7SHMFdfqV_Rg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </p>
      </ContentSection>

      <ContentSection3 background="Background2.png" height={395}>
        <h2 id="Specs">Hardware</h2>
        <ul>
          <li>
            <b>2x</b> Raspberry Pi mirco computers (1st for motion control, 2nd
            for face tracking)
          </li>
          <li>
            <b>28x</b> Servos (18 for legs, 4 for body, 6 for weapons)
          </li>
          <li>
            <b>1x</b> Camera (for face tracking)
          </li>
          <li>
            <b>3x</b> Servo drivers
          </li>
          <li>
            <b>2x</b> Lasers (how can you have a robot without lazors??!!! :))
          </li>
          <li>
            <b>1x</b> Distance sensor
          </li>
          <li>
            <b>1x</b> PS3 controller (or XBox controller when developing on
            Windows)
          </li>
        </ul>
        <h2>Software</h2>
        <ul>
          <li>
            <b>Custom engine</b> with special gait algorithm, animation
            playback, IK, face tracking, etc.
          </li>
          <li>
            <b>Unity pipeline</b> for converting animations and skeleton from 3D
            animation software into custom format.
          </li>
        </ul>
        <p>~250 hours of 3D printing using XYZPrinting DaVinci 1.0 printer.</p>
      </ContentSection3>

      <ContentSection className={SectionType.content1}>
        <h1>
          Story{" "}
          <span
            style={{
              textTransform: "lowercase",
              fontWeight: "normal",
              fontStyle: "italic",
            }}
          >
            (behind the scenes)
          </span>
        </h1>
        <p>
          This is just a short selections of shots from the making of the robot.
          If you want to read full story with all gory details you an do it{" "}
          <Link to="/Story">here...</Link>
        </p>
        <p style={{ textAlign: "center" }}>
          {storyFiles.map((file) => {
            const img = file[0];
            const anchor = file[1];
            return (
              <Fragment key={img}>
                <Link to={`/Story#${anchor}`}>
                  <img
                    src={`Data/Photos/StoryThumbs/${img}`}
                    style={{ width: "300px", height: "200px" }}
                  />
                </Link>
                &nbsp;
              </Fragment>
            );
          })}
          <br />
        </p>
      </ContentSection>

      <ContentSection className={SectionType.content2}>
        <h2>Special thanks</h2>
        <table
          style={{
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <tbody>
            <tr>
              <td>
                <a href="http://makerspace.lt">
                  <img src="Data/Images/Makerspace.png" />
                </a>
              </td>
              <td> </td>
              <td>
                <a href="http://www.robotikosmokykla.lt/">
                  <img src="Data/Images/RobotikosMokykla.png" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="http://makerspace.lt">Makerspace (Kaunas)</a>
              </td>
              <td style={{ width: "200px" }}> </td>
              <td>
                <a href="http://www.robotikosmokykla.lt/">Robotikos mokykla</a>
              </td>
            </tr>
          </tbody>
        </table>
      </ContentSection>

      <Copyright className={SectionType.content1} />
    </>
  );
}

export default Home;
