import "./App.css";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import Photos from "./pages/Photos";
import ScrollToAnchor from "./components/ScrollToAnchor";
import Story from "./pages/Story";
import MainMenu from "./components/MainMenu";

function App() {
  return (
    <>
      <MainMenu
        backgroundImage="background.png"
        backgroundSidesImage="backgroundSides.png"
      />

      <ScrollToAnchor />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Photos" element={<Photos />} />
        <Route path="/Photos.php" element={<Photos />} />
        <Route path="/Story" element={<Story />} />
        <Route path="/Story.php" element={<Story />} />
        {/* <Route component={NotFound} /> */}
      </Routes>
    </>
  );
}

export default App;
