import React from "react";
import ContentSection3 from "./ContentSection3";
import { Link } from "react-router-dom";

type Props = {
  backgroundImage: string;
  backgroundSidesImage: string;
};

function MainMenu(props: Props) {
  return (
    <ContentSection3
      background="Background0.png"
      height={120}
      className="menuContent"
      strechedBackground="BackgroundColor0.png"
    >
      <table style={{ height: "92px" }}>
        <tbody>
          <tr>
            <td style={{ width: "600px" }}> </td>
            <td style={{ width: "320px" }}>
              <Link to="/">
                <div style={{ height: "92px", width: "320px" }}> </div>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              <Link className="topLinksA" to="/#Video">
                Video
              </Link>
            </td>
            <td>
              <Link className="topLinksA" to="Photos">
                Photos
              </Link>
            </td>
            <td>
              <Link className="topLinksA" to="Story">
                Story
              </Link>
            </td>
            <td>
              <Link className="topLinksA" to="/#Specs">
                Specs
              </Link>
            </td>
            {/* <td><a class="topLinksA" href="index.php#Contact">Contact</a></td> */}
            <td>
              <a className="topLinksA" href="mailto:paulius.liekis@gmail.com">
                Contact
              </a>
            </td>
            <td style={{ width: "30px", background: "#fff" }}> </td>
            <td>
              <img src="Data/Images/MenuEnd.png" style={{ display: "block" }} />
            </td>
          </tr>
        </tbody>
      </table>
    </ContentSection3>
  );
}

export default MainMenu;
