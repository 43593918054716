type Props = {
  background: string;
  height: number;
  className?: string;
  strechedBackground?: string;
  children: React.ReactNode;
};

function ContentSection3({
  background,
  height,
  className = "mainContent",
  strechedBackground = "BackgroundColor.png",
  children,
}: Props) {
  return (
    <div
      style={{
        height: `${height}px`,
        backgroundImage: `url('Data/Images/${strechedBackground}')`,
        backgroundSize: "100% 100%",
        position: "relative",
      }}
    >
      <div
        style={{
          height: `${height}px`,
          width: "100%",
          backgroundImage: `url('Data/Images/${background}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          position: "absolute",
          top: "0px",
          left: "0px",
        }}
      >
        <div className={className + " content3"}>{children}</div>
      </div>
    </div>
  );
}

export default ContentSection3;
