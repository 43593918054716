import { storyFiles } from "./StoryFiles";
import ContentSection, { SectionType } from "../components/ContentSection";
import Copyright from "../components/Copyright";

function Story() {
  let sectionType = SectionType.content1;
  return (
    <>
      {storyPosts.map((post, index) => {
        const currentSection = sectionType;
        sectionType =
          sectionType === SectionType.content1
            ? SectionType.content2
            : SectionType.content1;

        return (
          <ContentSection
            className={currentSection}
            id={post.id}
            key={post.id ?? index}
          >
            {post.text}
          </ContentSection>
        );
      })}

      <Copyright className={sectionType} />
    </>
  );
}

export default Story;

const storyPosts: { id: string | undefined; text: JSX.Element }[] = [
  {
    id: undefined,
    text: (
      <>
        <h2>Story</h2>
        <p>
          This is a colletion of posts from forums and the language or
          formatting wasn't corrected :) It roughly reflects work in progress of
          the project.
        </p>
      </>
    ),
  },
  {
    id: undefined,
    text: (
      <>
        <p>
          I started working on<b> T08A2 / R3000 spider tank</b> from{" "}
          <i>Ghost In The Shell</i> movie a year ago. For those who haven't seen
          the movie - I would highly recommend, it's one of the best sci-fi
          anime movies.
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/GITS-Screenshots.jpg" />
        </p>
        <p>Scale 1:20 (roughly :))</p>
        <p>
          One of the biggest problems that I had from the start was non
          existence of blue prints, or actually anything that would be close to
          that. That's why scale is approximate :) So for "blueprints" I ended
          up with a few things:
        </p>
        <p></p>
        <ul>
          <li>Screenshots from the movie</li>
          <li>Smaller resin model from ebay, which isn't proper quality :)</li>
          <li>
            Someone made a 3D model, which I managed to aquire. It isn't very
            accurate, I would say it follows movie almost...
          </li>
        </ul>
        <p></p>
        <p>
          So yeah, none of these were precise. Actually, even frames from the
          movie sometimes contradict itself. To make matters worse I wanted to
          make a model which could move, so following sizes and strength of
          mechanical components had to be taken into consideration as well...
        </p>
        <p>
          Initial idea was to make the shell from a styrofoam, but during last
          year I bought a 3D printer, which came very handy when making these
          complex shapes.
        </p>
      </>
    ),
  },
  {
    id: "Video1",
    text: (
      <>
        <p>
          This is almost complete frame (except "arms"/weapons) and close to
          complete electronics:
        </p>
        <p></p>
        <p className="centered">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/bu7gaKglD3A?si=vdTzsFfz8juVSDA2"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </p>
        <p></p>
        <p>
          Motion is controlled by RaspberryPi. Programming in C++, from scratch.
          It is controlled with PS3 joystick, but there are plan to have some
          autonomous functionality.
        </p>
      </>
    ),
  },
  {
    id: "Program",
    text: (
      <>
        <p>A little bit from inside the "brain" of the robot:</p>
        <p className="centered">
          <img src="/Data/Photos/Story/GITS-Program.jpg" />
        </p>
        <p>
          The robot has full model of itself (in order to do correct simulation)
          and it's calculating where to make steps in such way that legs do not
          collide.
        </p>
      </>
    ),
  },

  {
    id: "Gun",
    text: (
      <>
        <p>A little bit from work progress:</p>
        <p>
          <b>Model in 3dsMax:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Gun/Gun01-3dsMax.jpg" />
        </p>
        <p>
          <b>Model in 3D printing program:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Gun/Gun02-Slicing.jpg" />
        </p>
        <p>
          <b>Model in 3D printing program (after slicing):</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Gun/Gun03-Slicing.jpg" />
        </p>
        <p>
          <b>Printed parts:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Gun/Gun04-3DPrinting.jpg" />
        </p>
        <p>
          Here should be some screenshots of work with sanding paper and
          acetone, because that's a big part of 3D printing, but about that some
          other time :)
        </p>
        <p>
          <b>Assembly of electronics (2x servos, 1x laser):</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Gun/Gun05-Electronics.jpg" />
        </p>
        <p>
          <b>Hiding of cables inside the ammunition belt:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Gun/Gun06-Belt.jpg" />{" "}
          <img src="/Data/Photos/Story/Gun/Gun07-Belt.jpg" />{" "}
          <img src="/Data/Photos/Story/Gun/Gun08-Belt.jpg" />
        </p>
        <p>
          <b>Painting of base color and assembly:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Gun/Gun10-Assembled.jpg" />
        </p>
      </>
    ),
  },
  {
    id: undefined,
    text: (
      <>
        <p>While other bake cookies for Xmas, I bake robot parts :)</p>
        <p className="centered">
          <img src="/Data/Photos/Story/GITS-Legs1.jpg" />
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/GITS-Legs2.jpg" />
        </p>
      </>
    ),
  },
  {
    id: undefined,
    text: (
      <>
        <p>A short video from testing of arms:</p>
        <p></p>
        <p className="centered">
          <iframe
            width="560"
            height="750"
            src="https://www.youtube.com/embed/J9P47HHAg6g?si=boLHrCc1ujNK42r2"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </p>
        <p></p>
      </>
    ),
  },
  {
    id: "Hardware",
    text: (
      <>
        <p>
          <b>Wiring complete!</b>
        </p>
        <p></p>
        <ul>
          <li>
            2x <i>Raspberry Pi micro computers</i> (1st for motion control, 2nd
            for face tracking)
          </li>
          <li>
            8x <i>Servos</i> (18 for legs, 4 for body, 6 for weapons)
          </li>
          <li>
            1x <i>Camera</i> (for face tracking)
          </li>
          <li>
            3x <i>Servo drivers</i>
          </li>
          <li>
            2x <i>Lasers</i> (how can you have a robot without lazors??!!! :))
          </li>
          <li>
            1x <i>Distance sensor</i>
          </li>
          <li>
            1x <i>PS3 controller</i>
          </li>
        </ul>
        ...and some armor:
        <p></p>
        <p className="centered">
          <img src="/Data/Photos/Story/GITS-Electronics3.jpg" />
        </p>
      </>
    ),
  },
  {
    id: "Legs",
    text: (
      <>
        <p>A little bit more of progress on the legs:</p>
        <p className="centered">
          <img src="/Data/Photos/Story/Legs/GITS00-3dsMax.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/GITS00-3dsMax2.jpg" />
        </p>
        <p>
          <b>3D printing:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Legs/S1/GITS01-LegS1-3DPrinted.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S2/GITS01-LegS2-3DPrinted.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S3/GITS02-LegS3-3DPrinted.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S3/GITS01-LegS3-3DPrinted-Toes.jpg" />
        </p>
        <p>
          <b>Polishing </b>(i.e. reducing visibility of 3D printing slices by
          sand paper and acetone; acetone also gives strength):
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Legs/S2/GITS02-LegS2-Polished.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S3/GITS03-LegS3-Polished.jpg" />
        </p>
        <p>
          <b>Assembling:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Legs/S3/GITS04-LegS3-AssembledSuspension.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S1/GITS02-LegS1-Assembled.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S2/GITS03-LegS2-Assembled.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S2/GITS04-LegS2-Assembled2.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S3/GITS05-LegS3-Assembled.jpg" />
        </p>
        <p>
          <b>Puttying</b> (is that a word? :)) (hiding marks of 3D printing even
          further):
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Legs/S1/GITS03-LegS1-Putty.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S3/GITS06-LegS3-Putty.jpg" />
        </p>
        <p>
          <b>Painting:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Legs/S1/GITS04-LegS1-Painted.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S2/GITS05-LegS2-Painted1.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S2/GITS05-LegS2-Painted2.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S2/GITS05-LegS2-Painted3.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S2/GITS05-LegS2-Painted4.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S3/GITS07-LegS3-Painted.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S3/GITS08-LegS3-PaintedToes.jpg" />{" "}
          <img src="/Data/Photos/Story/Legs/S3/GITS09-LegS3-Final.jpg" />
        </p>
        <p>
          <b>Result:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Legs/GITS10-Leg.jpg" />
        </p>
        <p>
          <b>"Suspension" of toes:</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Legs/GITS10-LegS3-Suspension.jpg" />
        </p>
        <p>
          I hope to finish main body and the I'll be able to attach the legs. I
          hope that everything will work once cables are connected (fingers
          crossed :)) Otherwise I'll have to disassemble (read: break) and redo
          some parts :)) The original plan was to have ability to disassemble,
          but that was too complicated, so I skipped that part :)
        </p>
      </>
    ),
  },
  {
    id: "Body",
    text: (
      <>
        <p>
          One of the most complicated parts: it's time to cover main-body to
          which all legs are attached:
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Body/GITS00-Body-Target01.jpg" />
        </p>
        <p>The optimistic plan was to print it from a couple of parts:</p>
        <p className="centered">
          <img src="/Data/Photos/Story/Body/GITS00-Body-Target02.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS00-Body-Target03.jpg" />
        </p>
        <p>
          <b>Problem #1</b>: printer shifts all layers by ~1cm sometime during
          printing. It still did in a different place when printing flipped
          part, although the problem was smaller, so I just fixed it by slicing
          printed part into two and gluing it together in correct place:.
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Body/GITS01-Body-3DPrintingProblems01.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS01-Body-3DPrintingProblems02.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS01-Body-3DPrintingProblems03.jpg" />
        </p>
        <p>
          I subdivided the remaining parts into smaller parts before printing in
          order to avoid same problems during print:
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Body/GITS02-Body-3DPrinting.jpg" />
        </p>
        <p>
          <b>Problem #2</b>: I could not assemble robot using these parts -
          there was no way to fit sphere in the middle through other parts once
          everything is assembled. I had to cut it into smaller pieces and
          attach these parts separately.
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Body/GITS03-Body-AssemblyProblems.jpg" />
        </p>
        <p>
          <b>Problem #3</b>: first design mistakes: my virtual project had no
          screws, so when I tried to fit to and bottom part they didn't fit
          where they belong (see the photo above), so there was a gap of 1cm
          between them. The main structure of the robot was done by hand without
          very precise engineering, so gaps between printed parts and servos
          were uneven. So even if I fixed design problem with the screws there
          was no I way to fix all gaps. I had to fallback to plan B: use of
          polystyrene and macroflex :) To make matters more fun I had to fit
          wires for 19 servos and plus a bunch of other wires :)
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Body/GITS04-Body-Assembly01.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS04-Body-Assembly02.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS04-Body-Assembly03.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS04-Body-Assembly04.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS06-Body-Shaping01.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS06-Body-Shaping02.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS06-Body-Shaping03.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS06-Body-Shaping04.jpg" />
        </p>
        <p>
          More shaping, macroflex, shaping, making surface stronger using PVA
          glue, polishing, glue, polishing, putty, polishing, putty, polishing:
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Body/GITS06-Body-Shaping05.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS06-Body-Shaping06.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS06-Body-Shaping07.jpg" />
        </p>
        <p>Painting and result:</p>
        <p className="centered">
          <img src="/Data/Photos/Story/Body/GITS08-Body-Result01.jpg" />{" "}
          <img src="/Data/Photos/Story/Body/GITS08-Body-Result02.jpg" />
        </p>
      </>
    ),
  },
  {
    id: "Tail",
    text: (
      <>
        <p>Some photos from older printing:</p>
        <p>
          "Find 10 differences!" or "My printer misunderstood what I actually
          want to print (correct piece is on the left side)":
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Tail/GITS00-Tail-PrintingIssues.jpg" />
        </p>
        <p>
          <b>"Tail":</b>
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Tail/GITS01-Tail-3dsMax.jpg" />
        </p>
        <p>
          The part is to big to make it as a single print - the max print
          dimensions 20x20x20cm. It was chopped into 6 individual pieces. The
          printer was misbehaving again a bit, so 2 out of 6 parts had to be
          corrected after printing:
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Tail/GITS01-Tail-Printing1.jpg" />{" "}
          <img src="/Data/Photos/Story/Tail/GITS01-Tail-Printing2.jpg" />{" "}
          <img src="/Data/Photos/Story/Tail/GITS01-Tail-Printing3.jpg" />
        </p>
        <p>Assembled:</p>
        <p className="centered">
          <img src="/Data/Photos/Story/Tail/GITS02-Tail-Assembly1.jpg" />{" "}
          <img src="/Data/Photos/Story/Tail/GITS02-Tail-Assembly2.jpg" />
        </p>
        <p>
          I covered the bottom piece with too much acetone, which resulted in
          bends...:) I had to cut it, screw to the frame, glue together and use
          a lot of putty afterwards :) There is still small gap between the top
          and bottom, so I added a screw to tighten them together after closing.
          The top and bottom are not glued together, since main electronics sits
          there and I need to service it occasionally or attach screen and
          keyboard when coding for it.
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Tail/GITS03-Tail-Putty1.jpg" />{" "}
          <img src="/Data/Photos/Story/Tail/GITS03-Tail-Putty2.jpg" />
        </p>
        <p>Result:</p>
        <p className="centered">
          <img src="/Data/Photos/Story/Tail/GITS04-Tail-Result1.jpg" />
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Tail/GITS04-Tail-Result2.jpg" />
        </p>
      </>
    ),
  },
  {
    id: "RPi",
    text: (
      <>
        <p>
          I found out that the old RaspberryPi (model B) is getting in a way of
          cables after assembling all printed parts, so I replaced it by newer
          model A+. Everything fits much better now:
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/GITS-RPiReplacement.jpg" />
        </p>
        <p>This is how it looked before the painting of details:</p>
        <p className="centered">
          <img src="/Data/Photos/Story/GITS-Assembled.jpg" />
        </p>
      </>
    ),
  },
  {
    id: "Electronics",
    text: (
      <>
        <p>I finally found time to fix electronics.</p>
        <p>
          Main change: I switched to power source with higher voltage (from 5V
          to 7.5V) - this gives more strength to the legs. One of the main
          problems was that it was having hard time standing on the legs after
          putting so much plastic on it. The robot weights ~5kg now. New and
          shiny power source:
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Electronics/GITS-PowerUpgrade1.jpg" />
        </p>
        <p>
          Of course RaspberryPi still needs 5V, so I had to put voltage
          stabiliser. That leads to some energy loses, but who cares when you're
          not planning to use batteries and you have 26A :))
        </p>
        <p>
          I also had to switch to shorter and thicker power cables for servos.
          And better plugs for those cables too. I'm glad I didn't have to pull
          any new cables through internals of the robot - it would be very hard
          to do at this point :) Although I still had to put a relay in the back
          and some thick cables. It doesn't look nice (when it's open), but it
          works! :)
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Electronics/GITS-PowerUpgrade2.jpg" />
        </p>
        <p>
          I found out that servos in arms take 6V max, so I had to put another
          voltage stabiliser :) Any change is never as easy as it seems at first
          sight:)
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Electronics/GITS-PowerUpgrade3.jpg" />
        </p>
      </>
    ),
  },
  {
    id: undefined,
    text: (
      <>
        <p>3Dprint.com did a story about my robot, yay! :)</p>
        <p>
          <a href="http://3dprint.com/54671/ghost-in-the-shell-robot-tank/">
            http://3dprint.com/54671/ghost-in-the-shell-robot-tank/
          </a>
        </p>
      </>
    ),
  },
  {
    id: "CenterSphere",
    text: (
      <>
        <p>
          Happy New Year everyone! I finally found time to get this project
          going again - there isn't better time than a night of New Year
          celebration :) I hope to get a better camera soon and make a final
          photoshoot....
        </p>
        <p>
          Here are some older picture about what happens when your 3D printer
          messes up your print and shifts half of a piece. Anyway, there is no
          thing that saw, glue and putty can't fix :))
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/CenterSphere/GITS01-CenterSphere-Printing.jpg" />{" "}
          <img src="/Data/Photos/Story/CenterSphere/GITS02-CenterSphere-Printed.jpg" />{" "}
          <img src="/Data/Photos/Story/CenterSphere/GITS03-CenterSphere-Putty.jpg" />{" "}
          <img src="/Data/Photos/Story/CenterSphere/GITS04-CenterSphere-Result.jpg" />
        </p>
      </>
    ),
  },
  {
    id: undefined,
    text: (
      <>
        <p>Painting the robot was a lot of fun. I've done these layers:</p>
        <p></p>
        <ul>
          <li>Prime color</li>
          <li>Shadows / light surfaces</li>
          <li>Weathering details</li>
          <li>Chipping</li>
          <li>Rust</li>
          <li>Weathering surfaces</li>
          <li>Oil/dirt</li>
        </ul>
        <p></p>
        <p>
          I overdid the chipping, but that's what happens when you're doing it
          for the first time :)
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Painting/GITS01-Highlights.jpg" />{" "}
          <img src="/Data/Photos/Story/Painting/GITS02-Chiping.jpg" />{" "}
          <img src="/Data/Photos/Story/Painting/GITS03-Rust.jpg" />{" "}
          <img src="/Data/Photos/Story/Painting/GITS04-Rust.jpg" />{" "}
          <img src="/Data/Photos/Story/Painting/GITS05-Final.jpg" />{" "}
          <img src="/Data/Photos/Story/Painting/GITS06-Final.jpg" />
        </p>
      </>
    ),
  },
  {
    id: undefined,
    text: (
      <>
        <p>
          BTW: people at a maker fair though it's made out of metal. Maybe the
          chipping is not as bad as I though. LOL.
        </p>
        <p>
          One of the goals of the project was to make the robot move in the
          nice, fluid way. A lot of work went into things that can not be shown
          by photos - coding and preparing the moves. To make it even more fun I
          had to redo some electronics - the robot stopped working after just
          sitting idle for half a year :) Anyway during the preparations I
          prepared a storyboards - my final animation was supposed to resemble
          the original movie:
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Filming/GITS00-StoryBoards.jpg" />{" "}
          <img src="/Data/Photos/Story/Filming/GITS01-StoryBoards.jpg" />
        </p>
        <p>I turned my flat into mini photo studio for a couple days :)</p>
        <p className="centered">
          <img src="/Data/Photos/Story/Filming/GITS02-Studio.jpg" />
        </p>
        <p>
          I'm not a photographer or filmmaker - using a tripod is as far as that
          goes :) but I decided to try using a couple of spotlights and a
          smoke-machine :) The smoke-machine turned out to be harder than I
          thought. You basically have to know how to use it. Having a good
          camera doesn't guarantee making good photos - the same for a smoke
          machine. Anyway, I needed it for making photos of the lasers, so it
          wasn't completely wasted :) Oh, and I haven't realised that smoke from
          smoke-machine raises straight up. You need cold smoke in order for it
          to sit on the ground, so I had to improvise that. 5 liter bottle
          filled with ice cubes served as cooler for smoke:
        </p>
        <p className="centered">
          <img src="/Data/Photos/Story/Filming/GITS03-SmokeMachine.jpg" />{" "}
          <img src="/Data/Photos/Story/Filming/GITS04-SmokeMachine.jpg" />
        </p>
      </>
    ),
  },
  {
    id: undefined,
    text: (
      <>
        <p>
          Here is a video comparing the original movie and live footage of my
          robot.
        </p>
        <p className="centered">
          <iframe
            width="900"
            height="315"
            src="https://www.youtube.com/embed/NXzSjUwHPJc?si=SfCV770AU2I55J-X"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </p>
      </>
    ),
  },
  { id: undefined, text: <p>End of story :)</p> },
];

const missingIds = storyFiles.filter(
  (file) => !storyPosts.some((post) => post.id === file[1])
);
console.assert(missingIds.length === 0, "Missing IDs", missingIds);
