export const storyFiles = [
  ["IMG_0964.JPG", "Video1"],
  ["GITS-Program.jpg", "Program"],
  ["DSC06943.JPG", "Legs"],

  ["DSC07107.JPG", "Legs"],
  ["IMG_0102.JPG", "CenterSphere"],
  ["DSC07119.JPG", "Legs"],

  ["GITS-RPiReplacement.jpg", "RPi"],
  ["GITS03-LegS3-Polished.jpg", "Legs"],
  ["_00_3dsMax01.jpg", "Body"],

  ["DSC07083.JPG", "Legs"],
  ["GITS10-LegS3-Suspension.jpg", "Legs"],
  ["_4_DSC07161.JPG", "Body"],

  ["DSC06934.JPG", "Legs"],
  ["DSC07159.JPG", "Legs"],
  ["_IMG_0092.JPG", "Tail"],

  ["DSC07202.JPG", "Legs"],
  ["Gun10-Assembled.jpg", "Gun"],
  ["IMG_0098.JPG", "Tail"],

  ["IMG_0291.JPG", "Electronics"],
  ["_2_IMG_0113.JPG", "Body"],
  ["IMG_0892.JPG", "Video1"],

  ["DSC07188.JPG", "Legs"],
  ["_DSC06870.JPG", "Hardware"],
  ["_DSC07091.JPG", "Legs"],

  ["_DSC06935.JPG", "Legs"],
  ["_DSC06937.JPG", "Legs"],
  ["_DSC07137.JPG", "Legs"],

  ["DSC07190.JPG", "Legs"],
  ["__DSC06845.JPG", "Gun"],
  ["__DSC06852.JPG", "Gun"],
];
