import { Fragment } from "react/jsx-runtime";
import ContentSection, { SectionType } from "../components/ContentSection";
import Copyright from "../components/Copyright";

function Photos() {
  const photos = ["2", "6", "8", "10", "1", "3", "7", "4", "5", "9"];
  return (
    <>
      <ContentSection className={SectionType.content1}>
        <p style={{ textAlign: "center" }}>
          {photos.map((photo) => {
            const url = `Data/Photos/Main/${photo}.jpg`;
            return (
              <Fragment key={photo}>
                <span id={`${photo}.jpg`} />
                <a href={url}>
                  <img src={url} style={{ width: "900px", height: "506px" }} />
                </a>{" "}
              </Fragment>
            );
          })}

          <br />
        </p>
      </ContentSection>

      <Copyright className={SectionType.content2} />
    </>
  );
}

export default Photos;
