export enum SectionType {
  content1 = "content1",
  content2 = "content2",
  content3 = "content3",
}

type Props = {
  className?: SectionType;
  id?: string;
  children: React.ReactNode;
};

function ContentSection({
  className = SectionType.content1,
  id,
  children,
}: Props) {
  return (
    <div className={className} id={id}>
      <div className="mainContent">{children}</div>
    </div>
  );
}

export default ContentSection;

// function contentSection($id = "")
// 	{
// 		global $sectionType;
// 		$type = $sectionType == 3 ? 1 : 3 - $sectionType;
// 		contentSectionEnd();

// 		$sectionType = $type;

// 		if ($id != "")
// 			$id = "id='" . $id . "'";

// 		echo("<div class='content$type' $id><div class='mainContent'>");
// 	}
